<template>
	<div>
		<!-- 公共头部 -->
		<Header></Header>
		
		<!-- <div class="blank_80"></div> -->
		<div class="section1">
			<div class="title" @click="markRead">全部已读</div>
		</div>
		
		<ul class="list">
			<li @click="navTo(item.id)" class="item" v-for="(item,index) in messageList" :key="index">
				<img @click.stop="item.checked = !item.checked" class="radio" :src=" item.checked ? require('@/assets/images/radio_checked.png') : require('@/assets/images/radio_uncheck.png')" />
				<div class="content">
					<div class="title">
						<font class="unread" v-if="item.status == 1"></font>{{item.type == 1 ? '管理员':'系统消息'}}
					</div>
					<div class="desc">{{item.title}}</div>
				</div>
				<div class="time">{{item.addtime}}</div>
			</li>
		</ul>
		<wd-pagination v-model="page" :total="total"></wd-pagination>
		<div class="blank_80"></div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '',
				limit: 10,
				page: 1,
				total: 0,
				messageList: []
			}
		},
		watch: {
			page() {
				if(this.$route.path == '/message/list'){
					this.getMessageList();
				}
			}
		},
		computed: {
			uid() {
				this.$store.commit('saveUid',this.$route.query.uid)
				return this.$route.query.uid;
			}
		},
		activated() {
			this.getUserInfo();
		},
		deactivated() {
			this.page = 1;
			this.total = 0;
			this.messageList = [];
		},
		methods: {
			navTo(id) {
				this.$router.push('/message/info?id=' + id);
			},
			// 设为已读
			async markRead() {
				var messageList = this.messageList;
				var checkedArray = [];
				for(var i = 0; i < messageList.length; i++) {
					// if(messageList[i]['checked']){
						checkedArray.push(messageList[i]['id']);
					// }
				}
				// if(!checkedArray.length){
				// 	this.$toast.info('未选中任何消息');
				// 	return false;
				// }
				var id = checkedArray.join(',');
				var uid = this.uid;
				var data = {
					id, uid
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/user/read', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('批量设为已读：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.$toast.success('操作成功');
					for(var i = 0; i < messageList.length; i++) {
						if(messageList[i]['checked']){
							messageList[i]['status'] = 1;
						}
					}
					this.$router.push('/message/list')
				} else {
					this.$toast.error('操作失败');
				}
			},
			// 获取消息通知列表
			async getMessageList(){
				var limit = this.limit;
				var page = this.page;
				var uid = this.uid;
				var data = {
					limit, page, uid
				};
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/user/message', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('消息通知：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var total = Number(res.data.count);
					this.total = total;
					var messageList = res.data.lists;
					for(var i = 0; i < messageList.length; i++) {
						messageList[i]['checked'] = false;
						messageList[i]['addtime'] = this.utils.js_date_time(messageList[i]['addtime'],4);
					}
					this.messageList = messageList;
				} else {
					this.messageList = [];
				}
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return ;
					}
						wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getMessageList();
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
		z-index: 333;
		padding: 0 0.3rem;
		
		width: 100%;
	
	}
	.section1 .title{
		font-size: 0.24rem;
		color: #ff6000;
		line-height: 0.4rem;
		border: 0.02rem solid #ff6000;
		padding: 0 0.14rem;
		border-radius: 0.5rem;
	}
	
	.list{
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
		margin-top: 0.3rem;
		margin-bottom: 0.3rem;
	}
	.list .item{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding-top: 0.4rem;
		padding-right: 0.3rem;
		padding-left: 0.9rem;
		padding-bottom: 0.4rem;
		border-top: 0.02rem solid #e7e7e7;
		position: relative;
	}
	.list .item:first-child{
		border-top: none;
	}
	.list .item .radio{
		position: absolute;
		left: 0.3rem;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
		width: 0.3rem;
		height: 0.3rem;
		padding: 0;
		display: none;
	}
	.list .item .content{
		padding-left: 0.95rem;
		background: url(../../../assets/images/icon68.png) no-repeat left center;
		background-size: 0.8rem 0.8rem;
	}
	.list .item .content .title{
		font-size: 0.32rem;
		color: #333333;
		line-height: 0.4rem;
		display: flex;
		align-items: center;
	}
	.list .item .content .title .unread{
		display: inline-block;
		width: 0.16rem;
		height: 0.16rem;
		background: #ff6000;
		border-radius: 100%;
		margin-right: 0.16rem;
	}
	.list .item .content .desc{
		font-size: 0.26rem;
		color: #969897;
		line-height: 0.4rem;
	}
	.list .item .time{
		font-size: 0.24rem;
		color: #969897;
		display: flex;
		align-items: center;
		line-height: 0.4rem;
	}
</style>